import {
    Box,
    Card,
    Cell,
    CustomModalLayout,
    Dropdown,
    DropdownLayoutValueOption,
    Layout,
    Modal,
} from '@wix/design-system';
import React, {useState} from 'react';
import {AggregationPeriod, VendorProductId, ViewSource} from '../models/models';
import BarChart from './BarChart';
import DateRangePickerComponent from './DateRangePickerComponent';
import ChartLegend from "./ChartLegend";

const AnalyticsModal: React.FC<{
    linkId: number,
    vendorProductId: VendorProductId,
    createdDate: Date,
    isQrCodeGenerated: boolean,
    onClose: () => void
}> =
    ({linkId, vendorProductId, createdDate, isQrCodeGenerated, onClose}) => {

    const [showModal, setShowModal] = useState(true);
    const [aggregatedPeriod, setAggregatedPeriod] = useState<AggregationPeriod>(AggregationPeriod.Daily)
    const [selectedId, setSelected] = useState<number | string>(0);
    const [startDate, setStartDate] = useState<Date>(initStartDate(aggregatedPeriod))
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [minDate, setMinDate] = useState<Date>(initMinDate(vendorProductId, aggregatedPeriod));
    const [datasetVisibility, setDatasetVisibility] = useState<Map<ViewSource, boolean>>(
        new Map<ViewSource, boolean>([[ViewSource.Browser, true], [ViewSource.Qr, true]])
    );

    const isMonthlyDisabled = vendorProductId === VendorProductId.basic || vendorProductId === VendorProductId.NOT_PURCHASED
    const options = [
        { id: 0, value: AggregationPeriod.Daily, disabled: false },
        { id: 1, value: AggregationPeriod.Monthly, disabled: isMonthlyDisabled }
    ];

    function initMinDate(vendorProductId: VendorProductId, aggregatedPeriod: AggregationPeriod) {
        const minDate = new Date();

        if (vendorProductId === VendorProductId.basic || vendorProductId === VendorProductId.FREE_TRIAL) {
            minDate.setDate(minDate.getDate() - 29);
        } else if (vendorProductId === VendorProductId.business) {
            if (aggregatedPeriod === AggregationPeriod.Monthly) {
                minDate.setMonth(minDate.getMonth() - 2);
                minDate.setDate(1);
            } else {
                minDate.setDate(minDate.getDate() - 89);
            }
        } else if (vendorProductId === VendorProductId.ultimate) {
            if (aggregatedPeriod === AggregationPeriod.Monthly) {
                minDate.setMonth(minDate.getMonth() - 11);
                minDate.setDate(1);
            } else {
                minDate.setDate(minDate.getDate() - 364);
            }
        }

        if (createdDate < minDate) {
            return minDate;
        }

        return createdDate;
    }

    const handleClose = () => {
        setShowModal(false);
        onClose();
    }

    function initStartDate(newAggregationPeriod: AggregationPeriod) {
        const initStartDate: Date = new Date();

        switch (newAggregationPeriod) {
            case AggregationPeriod.Daily:
                initStartDate.setDate(initStartDate.getDate() - 29);
                break;
            case AggregationPeriod.Monthly:
                initStartDate.setMonth(initStartDate.getMonth() - 2)
                initStartDate.setDate(1);
                break;
        }

        if (createdDate < initStartDate) {
            return initStartDate;
        }
        return createdDate;
    }

    const handleAggregationPeriodChange = (valueOptions: DropdownLayoutValueOption) => {
        const newValue = valueOptions.value;
        setSelected(valueOptions.id);
        const newAggregationPeriod = AggregationPeriod[newValue as keyof typeof AggregationPeriod]
        setAggregatedPeriod(newAggregationPeriod);
        setMinDate(initMinDate(vendorProductId, newAggregationPeriod))
        setStartDate(initStartDate(newAggregationPeriod));
        setEndDate(new Date());
    }

    const handleDateRangeChange = (dates: Date[]) => {
        const [startDate, endDate] = dates;

        if (startDate != null && endDate != null) {
            setStartDate(startDate);
            setEndDate(endDate);
        }
    }

    const handleLegendClick = (viewSource: ViewSource) => {
        const newDatasetVisibility = new Map(datasetVisibility);
        newDatasetVisibility.set(viewSource, !newDatasetVisibility.get(viewSource));
        setDatasetVisibility(newDatasetVisibility);
    }

    return (
        <Modal isOpen={showModal}
               onRequestClose={handleClose}
               shouldCloseOnOverlayClick
               screen="desktop">
            <CustomModalLayout
                title="Link Clicks"
                onCloseButtonClick={handleClose}
                height="80%"
                width="75%"
                content={
                    <Card>
                        <Layout>
                            <Cell span={6}>
                                <Box direction="horizontal" align="left" verticalAlign="middle" gap="SP2" marginTop="SP1">
                                    <ChartLegend isQrCodeGenerated={isQrCodeGenerated} onClick={(viewSource: ViewSource) => handleLegendClick(viewSource)} />
                                </Box>
                            </Cell>

                            <Cell span={6}>
                                <Box direction="horizontal" align="right" verticalAlign="middle" gap="SP2" marginTop="SP1">
                                    <Dropdown border="round"
                                              options={options}
                                              selectedId={selectedId}
                                              onSelect={(e) => handleAggregationPeriodChange(e)} />
                                    <DateRangePickerComponent minDate={minDate}
                                                              maxDate={new Date()}
                                                              startDate={startDate}
                                                              endDate={endDate}
                                                              aggregationPeriod={aggregatedPeriod}
                                                              onClose={handleDateRangeChange} />
                                </Box>
                            </Cell>
                        </Layout>

                        <BarChart linkId={linkId}
                                  startDate={startDate}
                                  endDate={endDate}
                                  aggregationPeriod={aggregatedPeriod}
                                  datasetVisibility={datasetVisibility} />
                    </Card>
                }
            />
        </Modal>
    );
}

export default AnalyticsModal;
