import {
    Box,
    Text
} from '@wix/design-system';
import React from 'react';
import {ShortLinkResponse} from '../models/models';

const ShortLinkListItem: React.FC<{shortLink: ShortLinkResponse}> = ({ shortLink}) => {

    const removeSchema = (url: string) => {
        const parsedUrl = new URL(url);
        return parsedUrl.host + parsedUrl.pathname;
    }

    return (
        <Box direction="vertical">
            <Text ellipsis weight="normal">{shortLink.title}</Text>
            <Text size="small" secondary>
                <a href={shortLink.shortUrl} target="_blank" rel="noopener noreferrer">{removeSchema(shortLink.shortUrl)}</a>
            </Text>
        </Box>
    );
};

export default ShortLinkListItem;
