import {Route, Routes} from "react-router-dom";
import TinyLinks from "./components/TinyLinks";

function App() {
    return (
        <Routes>
            <Route path="/" element={<TinyLinks/>} />
        </Routes>
    );
}

export default App;
