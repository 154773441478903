import {
    MessageModalLayout,
    Modal,
    Text
} from '@wix/design-system';
import React, {useState} from 'react';
import {deleteShortLinkByShortUrl} from '../services/short-link-client';
import {useSearchParams} from "react-router-dom";

const DeleteShortLink: React.FC<{ shortUrl: string, longUrl: string, onDelete: () => void, onClose: () => void }> =
    ({ shortUrl, longUrl, onDelete, onClose }) => {

  const [showModal, setShowModal] = useState(true);
  const [searchParams] = useSearchParams();

  const handleDelete = (shortUrl: string) => {
      const shortUrlCode = shortUrl.split('/')[3];
      deleteShortLinkByShortUrl(shortUrlCode, searchParams)
          .then(() => onDelete())
          .finally(() => {
              setShowModal(false);
              onClose();
          });
  };

  const handleClose = () => {
      setShowModal(false);
      onClose();
  }

    return (
        <Modal isOpen={showModal}
               onRequestClose={handleClose}
               shouldCloseOnOverlayClick
               screen="desktop">
            <MessageModalLayout
                theme={'destructive'}
                title="Delete this link?"
                primaryButtonText="Delete"
                primaryButtonOnClick={ () => handleDelete(shortUrl) }
                secondaryButtonText="Cancel"
                secondaryButtonOnClick={handleClose}
                onCloseButtonClick={handleClose}
                content={
                    <Text>
                        You’re about to delete your short link <b>{longUrl}</b>.
                        Your short link will become unpublished and can’t be edited.
                    </Text>
                }
            />
        </Modal>
    );
};

export default DeleteShortLink;
