export interface ShortLinkRequest {
    longUrl: string;
    title?: string;
    customShortUrlCode?: string;
    generateQrCode: boolean;
}

export interface ShortLinkResponse {
    linkId: number;
    shortUrl: string;
    shortUrls: string[];
    longUrl: string;
    title: string;
    viewCount: number;
    isQrCodeGenerated: boolean;
    createdDate: Date;
    ttl: number;
}

export interface ShortLinkUpdateRequest {
    id: number;
    title: string;
}

export interface ShortLinkDeleteRequest {
    shortUrlCodes: string[]
}

export enum AggregationPeriod {
    Daily = 'Daily',
    Monthly = 'Monthly'
}

export enum ViewSource {
    Qr = 'QR',
    Browser = 'Browser'
}

export interface ViewAnalyticsRequest {
    linkId: number,
    startDate: string,
    endDate: string, // TODO change to iso date
    timeZone: string,
    aggregationPeriod: AggregationPeriod
}

export interface ViewAnalyticsResponse {
    linkId: number,
    views: Map<string, number>,
    qrViews: Map<string, number>,
}

export enum VendorProductId {
    NOT_PURCHASED = 'NOT_PURCHASED',
    FREE_TRIAL = 'FREE_TRIAL',
    basic = "basic",
    business = "business",
    ultimate = "ultimate"
}

export interface InstanceInfoResponse {
    appId: string,
    siteId: string,
    vendorProductId: VendorProductId,
    availableLinks: number,
    availableCustomShortUrlCodes: number,
    availableQrCodes: number
}
