import React from 'react';
import {IconButton, PopoverMenu} from "@wix/design-system";
import {DownloadImport} from "@wix/wix-ui-icons-common";

interface DropdownButtonProps {
    options: string[];
    onClick: (option: string) => void;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({ options, onClick }) => {

    const handleButtonClick = (option: string) => {
        onClick(option);
    };

    return (
        <PopoverMenu appendTo={"parent"}
                     placement={"right-end"}
                     showArrow={true}
                     triggerElement={({ toggle, open, close }) => (
                         <IconButton size="large"
                                     skin="standard"
                                     priority="secondary"
                                     onClick={toggle}
                                     onMouseEnter={open}
                                     onMouseLeave={close}
                         >
                             <DownloadImport />
                         </IconButton>
                     )}
        >
            <PopoverMenu.MenuItem text="Download PNG"
                                  onClick={() => handleButtonClick(options[0])}
            />
            <PopoverMenu.MenuItem text="Download SVG"
                                  onClick={() => handleButtonClick(options[1])}
            />
            <PopoverMenu.MenuItem text="Download JPG"
                                  onClick={() => handleButtonClick(options[2])}
            />
        </PopoverMenu>
    );
};

export default DropdownButton;
