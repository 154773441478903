import {
    CustomModalLayout,
    FormField,
    Input,
    Modal,
    Text,
} from '@wix/design-system';
import {StatusType} from '@wix/design-system/dist/types/FormField';
import React, {
    ChangeEvent,
    useState,
} from 'react';
import {ShortLinkUpdateRequest} from '../models/models';
import {useSearchParams} from "react-router-dom";
import {updateLinkSchema} from "../validations/UpdateLinkValidation";
import {updateLink} from "../services/short-link-client";
import {ValidationError} from "yup";

const EditShortLink: React.FC<{ linkId: number, title: string, longUrl: string, onClose : () => void, onSave : () => void }> =
    ({ linkId, title, longUrl, onClose, onSave }) => {

    const [showModal, setShowModal] = useState(true);
    const [inputTitleValue, setInputTitleValue] = useState(title);
    const [formFieldStatus, setFormFiledStatus] = useState<StatusType>()
    const [formStatusMessage, setFormStatusMessage] = useState("");
    const [updateLinkRequest, setUpdateLinkRequest] = useState<ShortLinkUpdateRequest>({ id: -1, title: title });
    const [searchParams] = useSearchParams();

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, inputName: string) => {
        const newValue = e.target.value;
        setInputTitleValue(newValue);
        setUpdateLinkRequest(prevState => ({
            ...prevState,
            [inputName]: newValue.trim()
        }));
    }

    const handleEdit = (id: number) => {
        try {
            updateLinkSchema.validateSync({ title: updateLinkRequest.title });

            updateLinkRequest.id=id;
            updateLink(updateLinkRequest, searchParams)
                .then(() => onSave())
                .finally(() => {
                    setShowModal(false);
                    onClose();
                })
        } catch (err) {
            if (err instanceof ValidationError) {
                setFormFiledStatus("error")
                setFormStatusMessage(err.message)
            }
        }
    };

    const handleClose = () => {
        setShowModal(false);
        onClose();
    }

    return (
        <Modal isOpen={showModal}
               onRequestClose={handleClose}
               shouldCloseOnOverlayClick
               screen="desktop">

            <CustomModalLayout primaryButtonText="Edit"
                               primaryButtonOnClick={() => handleEdit(linkId)}
                               secondaryButtonText="Cancel"
                               secondaryButtonOnClick={handleClose}
                               onCloseButtonClick={handleClose}
                               title="Edit Short Link"
                               overflowY="none"
                               content={
                                   <>
                                       <FormField>
                                           <Text>URL</Text>
                                           <Input size="medium" value={longUrl} readOnly />
                                       </FormField>
                                       <p/>
                                       <FormField status={formFieldStatus} statusMessage={formStatusMessage}>
                                           <Text>Title</Text>
                                           <Input size="medium"
                                                  value={inputTitleValue}
                                                  onChange={e => handleInputChange(e, 'title')} />
                                       </FormField>
                                   </>
                               }
            />
        </Modal>
    );
};

export default EditShortLink;
