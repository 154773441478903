import React from "react";
import {Button, Text, Tooltip} from "@wix/design-system";
import {ViewSource} from "../models/models";

const ChartLegend: React.FC<{ isQrCodeGenerated: boolean, onClick : (viewSource: ViewSource) => void }> = ({ isQrCodeGenerated, onClick }) => {

    const [isLinkClicksSelected, setLinkClicksSelected] = React.useState(true);
    const [isQrViewsSelected, setIsQrViewsSelected] = React.useState(true);

    const handleLegendClick = (viewSource: ViewSource) => {
        if (viewSource === ViewSource.Browser) {
            setLinkClicksSelected(!isLinkClicksSelected)
        }

        if (viewSource === ViewSource.Qr) {
            setIsQrViewsSelected(!isQrViewsSelected)
        }

        onClick(viewSource);
    }

    return (
        <>
            <Button skin="light"
                    priority="primary"
                    style={{ backgroundColor: isLinkClicksSelected ? 'rgb(230, 240, 255)' : 'rgb(255, 255, 255)' }}
                    onClick={() => handleLegendClick(ViewSource.Browser)}>
                <div style={{
                    width: '30px',
                    height: '15px',
                    backgroundColor: 'rgb(56, 153, 237)',
                    marginRight: '4px'
                }}/>
                <Text>Link Clicks</Text>
            </Button>
            <Tooltip content={"Generate QR-code to enable"} disabled={isQrCodeGenerated}>
                <Button skin="light"
                        priority="secondary"
                        disabled={!isQrCodeGenerated}
                        style={{ backgroundColor: isQrCodeGenerated ? (isQrViewsSelected ? 'rgb(230, 240, 255)' : 'rgb(255, 255, 255)') : 'rgba(167, 172, 183, 0.5)' }}
                        onClick={() => handleLegendClick(ViewSource.Qr)}>
                    <div style={{
                        width: '30px',
                        height: '15px',
                        backgroundColor: 'rgb(159, 209, 246)',
                        marginRight: '4px'
                    }}/>
                    <Text>QR-code views</Text>
                </Button>
            </Tooltip>
        </>
    )
}

export default ChartLegend;
