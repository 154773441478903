import {
    Box,
    Button,
    Card,
    Page,
    Table,
    TableActionCell,
    TableToolbar,
    Text,
    TextButton,
    Tooltip,
    WixDesignSystemProvider
} from '@wix/design-system';
import {Activity, Add, Delete, Edit, Visible} from '@wix/wix-ui-icons-common';
import React, {useEffect, useState} from 'react';
import {InstanceInfoResponse, ShortLinkResponse, VendorProductId,} from '../models/models';
import {handleCopy} from '../services/clipboard-service';
import {getShortLinks} from '../services/short-link-client';
import AnalyticsModal from './AnalyticsModal';
import CutLink from './CutLink';
import DeleteShortLink from './DeleteShortLink';
import ShortLinkListItem from './ShortLinkListItem';
import {useSearchParams} from "react-router-dom";
import EditShortLink from "./EditShortLink";
import {getInstanceInfo} from "../services/instance-client";
import UpgradeBanner from "./UpgradeBanner";
import QrCodeModal from "./QrCodeModal";
import QrCodeConfirmationModal from "./QrCodeConfirmationModal";
import QrCodeIcon from "./QrCodeIcon";

import '@wix/design-system/styles.global.css';
import '../styles/main_styles.css';

const TinyLinks: React.FC = () => {

  const [showCutLinkModal, setShowCutLinkModal] = useState(false);
  const [showEditLinkModal, setShowEditLinkModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [showQrCodeConfirmationModal, setShowQrCodeConfirmationModal] = useState(false);
  const [isQrCodeGenerated, setIsQrCodeGenerated] = useState(false);
  const [showAnalyticsModal, setShowAnalyticsModal] = useState(false);
  const [showUpgradeBanner, setShowUpgradeBanner] = useState(false);
  const [shortLinkToDelete, setShortLinkToDelete ] = useState('');
  const [shortLinksResponse, setShortLinksResponse] = useState<ShortLinkResponse[]>([]);
  const [instanceInfoResponse, setInstanceInfoResponse] = useState<InstanceInfoResponse>(
      {appId: '', siteId: '', vendorProductId: VendorProductId.NOT_PURCHASED, availableLinks: 0, availableCustomShortUrlCodes: 0, availableQrCodes: 0}
  );
  const [linkIdForAction, setLinkIdForAction] = useState(1);
  const [longUrl, setLongUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [linkTitle, setLinkTitle] = useState('');
  const [createdDate, setCreatedDate] = useState<Date>(new Date());
  const [searchParams] = useSearchParams();

  useEffect(() => {
      refresh();
      }, []);

  const refresh = () => {
      fetchInstanceInfo();
      fetchShortLinks();
  }

  const fetchShortLinks = () => {
      getShortLinks(searchParams)
          .then(res => {
                setShortLinksResponse(res.data)
          })
  };

  const fetchInstanceInfo = () => {
      getInstanceInfo(searchParams)
          .then(res => {
              setInstanceInfoResponse(res.data)
              
              const vendorProductId = res.data.vendorProductId;
              const availableLinks = res.data.availableLinks;

              if (vendorProductId === VendorProductId.NOT_PURCHASED
                  || vendorProductId === VendorProductId.FREE_TRIAL
                  || (vendorProductId === VendorProductId.basic && availableLinks / 10 <= 0.2)
                  || (vendorProductId === VendorProductId.business && availableLinks / 100 <= 0.1) ) {
                  setShowUpgradeBanner(true);
              } else {
                  setShowUpgradeBanner(false);
              }
          })
  }

  const linksListItems = shortLinksResponse.map(shortLinkResponse => ({
      linkId: shortLinkResponse.linkId,
      shortLinkItem: (<ShortLinkListItem shortLink={shortLinkResponse}/>),
      views: (
              <Box>
                  <Tooltip inline content="Total Clicks">
                    <Visible color={"rgb(56, 153, 237)"}/>
                  </Tooltip>
                  <Text weight="thin">{shortLinkResponse.viewCount}</Text>
              </Box>),
      longUrl: shortLinkResponse.longUrl,
      shortUrl: shortLinkResponse.shortUrl,
      title: shortLinkResponse.title,
      isQrCodeGenerated: shortLinkResponse.isQrCodeGenerated,
      createdDate: shortLinkResponse.createdDate
  }));

  const isDisabled = instanceInfoResponse.vendorProductId === VendorProductId.NOT_PURCHASED;
  const isCreateLinkDisabled = instanceInfoResponse.vendorProductId === VendorProductId.NOT_PURCHASED || instanceInfoResponse.availableLinks <= 0;
  const isGeneratedQrCodeDisabled = instanceInfoResponse.vendorProductId === VendorProductId.NOT_PURCHASED || instanceInfoResponse.availableQrCodes <= 0;

  const primaryAction = (url: string) => ({
      text: "Copy",
      onClick: () => handleCopy(url)
  });

  const secondaryActions = (linkId: number,
                             shortUrl: string,
                             longUrl: string,
                             title: string,
                             isQrCodeGenerated: boolean,
                             createdDate: Date) : (
                                 {
                                    text: string;
                                    icon: React.JSX.Element;
                                    visibility: string;
                                    skin: 'dark' | 'destructive' | undefined;
                                    disabled: boolean;
                                    onClick: () => void;
                                 } |
                                 {
                                     divider: boolean
                                 })[]  => ([
      {
        text:  isQrCodeGenerated ? "QR-code generated" : `${instanceInfoResponse.availableQrCodes} available QR-codes`,
        icon: <QrCodeIcon isQrCodeGenerated={isQrCodeGenerated}/>,
        visibility: 'always',
        skin: 'dark',
        disabled: isGeneratedQrCodeDisabled && !isQrCodeGenerated,
        onClick: () => {
            if (isQrCodeGenerated) {
                setShowQrCodeModal(true);
                setLinkIdForAction(linkId);
                setShortUrl(shortUrl);
            } else {
                setShowQrCodeConfirmationModal(true);
                setLinkIdForAction(linkId);
                setShortUrl(shortUrl);
            }
        }
      },
      {
          text: "Analytics",
          icon: <Activity />,
          visibility: 'always',
          skin: 'dark',
          disabled: isDisabled,
          onClick: () => {
              setShowAnalyticsModal(true);
              setLinkIdForAction(linkId);
              setCreatedDate(createdDate);
              setIsQrCodeGenerated(isQrCodeGenerated);
          }
      },
      {
          text: "Edit",
          icon: <Edit />,
          visibility: 'always',
          skin: 'dark',
          disabled: isDisabled,
          onClick: () => {
              setShowEditLinkModal(true);
              setLinkIdForAction(linkId);
              setLinkTitle(title);
              setLongUrl(longUrl);
          }
      },
      {
          divider: true
      },
      {
          text: "Delete",
          icon: <Delete />,
          visibility: 'always',
          skin: 'destructive',
          disabled: isDisabled,
          onClick: () => {
              setShowDeleteModal(true);
              setShortLinkToDelete(shortUrl);
              setLongUrl(longUrl);
          }
      }
  ]);

  const NUM_OF_VISIBLE_SECONDARY_ACTION = 2;

  const columns = [
      {
          title: "Links",
          render: (row: any) => row.shortLinkItem,
          width: '30%'
      },
      {
          title: "Views",
          render: (row: any) => row.views,
          width: '35%'
      },
      {
          title: "Actions",
          render: (row: any) => (
              <TableActionCell primaryAction={primaryAction(row.shortUrl)}
                               secondaryActions={secondaryActions(row.linkId, row.shortUrl, row.longUrl, row.title, row.isQrCodeGenerated, row.createdDate)}
                               alwaysShowSecondaryActions={true}
                               numOfVisibleSecondaryActions={NUM_OF_VISIBLE_SECONDARY_ACTION}
              />
          )
      }
  ];

  return (
    <WixDesignSystemProvider>

        <Page height='100vh'>
            <Page.Header
                title="TinyLinks"
                subtitle="Generate and Track Short Links & QR Codes"
                actionsBar={
                    <Tooltip inline content={`${instanceInfoResponse.availableLinks} available links`}>
                        <Button size="medium"
                                    prefixIcon={<Add />}
                                    disabled={isCreateLinkDisabled}
                                    onClick={() => setShowCutLinkModal(true)}>
                            Add Link
                        </Button>
                    </Tooltip>
                }
            />
                
            <Page.Content>
                <Card>
                    <Table data={linksListItems} columns={columns}>
                        <TableToolbar>
                            <TableToolbar.Title>Short Links Collection</TableToolbar.Title>
                        </TableToolbar>

                        <Table.Content titleBarVisible={false} />

                        { linksListItems.length === 0 && (
                            <Table.EmptyState title="Add your first link"
                                              subtitle="Paste long URL and create beautiful short link">
                                <TextButton size="small"
                                            prefixIcon={<Add />}
                                            skin="standard"
                                            priority="secondary"
                                            disabled={isCreateLinkDisabled}
                                            onClick={() => setShowCutLinkModal(true)}>
                                                Add link
                                </TextButton>
                            </Table.EmptyState>
                        )}
                    </Table>
                </Card>
            </Page.Content>

            { showUpgradeBanner && <Page.FixedFooter children={<UpgradeBanner appId={instanceInfoResponse.appId}
                                                                              siteId={instanceInfoResponse.siteId}
                                                                              vendorProductId={instanceInfoResponse.vendorProductId}/>} /> }
        </Page>

        { showCutLinkModal && <CutLink availableQrCodes={instanceInfoResponse.availableQrCodes}
                                       availableCustomShortUrlCodes={instanceInfoResponse.availableCustomShortUrlCodes}
                                       onSave={() => refresh()}
                                       onClose={() => setShowCutLinkModal(false)} /> }
        { showEditLinkModal && <EditShortLink linkId={linkIdForAction}
                                              title={linkTitle}
                                              longUrl={longUrl}
                                              onSave={() => refresh()}
                                              onClose={() => setShowEditLinkModal(false)} /> }
        { showDeleteModal && <DeleteShortLink shortUrl={shortLinkToDelete}
                                              longUrl={longUrl}
                                              onDelete={() => refresh()}
                                              onClose={() => setShowDeleteModal(false)} /> }
        { showAnalyticsModal && <AnalyticsModal linkId={linkIdForAction}
                                                vendorProductId={instanceInfoResponse.vendorProductId}
                                                createdDate={createdDate}
                                                isQrCodeGenerated={isQrCodeGenerated}
                                                onClose={() => setShowAnalyticsModal(false)} /> }
        { showQrCodeConfirmationModal && <QrCodeConfirmationModal linkId={linkIdForAction}
                                                                  onClose={() => setShowQrCodeConfirmationModal(false)} 
                                                                  onSubmit={() => {
                                                                      setShowQrCodeConfirmationModal(false)
                                                                      setShowQrCodeModal(true);
                                                                      refresh()
                                                                  }} /> }
        { showQrCodeModal && <QrCodeModal shortUrl={shortUrl}
                                          onClose={() => setShowQrCodeModal(false)} /> }

    </WixDesignSystemProvider>
  );
};

export default TinyLinks;
