import {
    Checkbox,
    CustomModalLayout,
    FormField,
    Input,
    Modal,
    Text,
    Tooltip,
} from '@wix/design-system';
import {StatusType} from '@wix/design-system/dist/types/FormField';
import React, {
    ChangeEvent,
    useState,
} from 'react';
import {ShortLinkRequest} from '../models/models';
import {cutLink} from '../services/short-link-client';
import {cutLinkSchema} from '../validations/CutLinkValidation';
import {useSearchParams} from "react-router-dom";
import {ValidationError} from 'yup';

const CutLink: React.FC<{ availableQrCodes: number, availableCustomShortUrlCodes: number, onClose : () => void, onSave : () => void }> = ({ availableQrCodes, availableCustomShortUrlCodes, onClose, onSave }) => {

  const [showModal, setShowModal] = useState(true);
  const [urlFieldStatus, setUrlFiledStatus] = useState<StatusType>();
  const [urlStatusMessage, setUrlStatusMessage] = useState("");
  const [titleFieldStatus, setTitleFiledStatus] = useState<StatusType>();
  const [titleStatusMessage, setTitleStatusMessage] = useState("");
  const [customShortUrlFieldStatus, setCustomShortUrlFieldStatus] = useState<StatusType>();
  const [customShortUrlFieldStatusMessage, setCustomShortUrlFieldStatusMessage] = useState("");
  const [cutLinkRequest, setCutLinkRequest] = useState<ShortLinkRequest>({ longUrl: '', generateQrCode: false });
  const [searchParams] = useSearchParams();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>, inputName: string) => {
      const newValue = event.target.value;
      setCutLinkRequest(prevState => ({
          ...prevState,
          [inputName]: newValue.trim()
      }));
  }

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCutLinkRequest(prevState => ({ ...prevState, generateQrCode: event.target.checked }));
    };

  const setInputFieldError = (field: string | undefined, message: string) => {
      if (field === 'url') {
          setUrlFiledStatus("error")
          setUrlStatusMessage(message)
      } else if (field === 'title') {
          setTitleFiledStatus("error")
          setTitleStatusMessage(message)
      } else if (field === 'customShortUrlCode') {
          setCustomShortUrlFieldStatus('error')
          setCustomShortUrlFieldStatusMessage(message)
      }
  }

  const handleSave = async () => {
      try {
          // Validate input
          cutLinkSchema.validateSync({
              url: cutLinkRequest.longUrl,
              title: cutLinkRequest.title,
              customShortUrlCode: cutLinkRequest.customShortUrlCode,
          });

          try {
              // Call the cutLink function and await its completion
              await cutLink(cutLinkRequest, searchParams);

              // If successful, proceed with saving and closing
              onSave();
              setShowModal(false);
              onClose();
          } catch (error: any) {
              // Handle specific error codes
              if (error.response && error.response.status === 409) {
                  console.log("409 error");
                  setInputFieldError('customShortUrlCode', 'Provided short link already exists');
              }
          }
      } catch (err: any) {
          // Handle validation errors
          if (err instanceof ValidationError) {
              setInputFieldError(err.path, err.message);
          }
      }
  };

  const handleClose = () => {
      setShowModal(false);
      onClose();
  }

  return (
    <Modal isOpen={showModal}
           onRequestClose={handleClose}
           shouldCloseOnOverlayClick
           screen="desktop">

        <CustomModalLayout primaryButtonText="Shorten URL"
                           primaryButtonOnClick={handleSave}
                           secondaryButtonText="Cancel"
                           secondaryButtonOnClick={handleClose}
                           onCloseButtonClick={handleClose}
                           title="New Short Link"
                           overflowY="none"
                           content={
                               <>
                                   <FormField status={urlFieldStatus} statusMessage={urlStatusMessage}>
                                       <Text>URL</Text>
                                       <Input size="medium"
                                              placeholder="http(s)://"
                                              onChange={event => handleInputChange(event, 'longUrl')}/>
                                   </FormField>
                                   <p/>
                                   <FormField status={titleFieldStatus} statusMessage={titleStatusMessage} required={false}>
                                       <Text>Title (optional)</Text>
                                       <Input size="medium"
                                              placeholder="Title"
                                              onChange={event => handleInputChange(event, 'title')}/>
                                   </FormField>
                                   <p/>
                                   <FormField status={customShortUrlFieldStatus}
                                              statusMessage={customShortUrlFieldStatusMessage}
                                              required={false}>
                                       <Text>Custom link (optional)</Text>
                                       <Tooltip content={`${availableCustomShortUrlCodes} available URLs`}
                                                placement='top-start'
                                                textAlign='start'>
                                           <Input size="medium"
                                                  prefix={<Input.Affix value="tnlnk.co/"/>}
                                                  disabled={availableCustomShortUrlCodes <= 0}
                                                  onChange={event => handleInputChange(event, 'customShortUrlCode')}/>
                                       </Tooltip>
                                   </FormField>
                                   <p/>
                                   <FormField>
                                       <Checkbox tooltipContent={`${availableQrCodes} available QR-codes`}
                                                 tooltipProps={{
                                                     placement: 'top-start',
                                                     textAlign: 'start',
                                                 }}
                                                 checked={cutLinkRequest.generateQrCode}
                                                 disabled={availableQrCodes <= 0}
                                                 onChange={event => handleCheckboxChange(event)}>
                                           Generate QR-code
                                       </Checkbox>
                                   </FormField>
                               </>
                           }
        />
    </Modal>
    );
};

export default CutLink;
